<template>
  <div class="card w-75 ml-4" style="width: 18rem;">
    <ul class="list-group list-group-flush cursor-pointer">
      <li v-for="(item, index) in listItems" :key="index" class="list-group-item"
          :class="index === activeIndex ? 'bg-primary text-white' : ''" @click="changeActiveList(index)">
        {{item.title}}
        <p>{{item.date}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserHomeList',
  props:{
    lists:{
      type: Array,
      default() {
        return [];
      },
      required: true,
    }
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  computed: {
    listItems: {
      get() {
        return this.lists;
      },
      set(val) {
        console.log(val)
      }
    }
  },
  methods:{
    changeActiveList(index) {
      this.activeIndex=index;
      this.$emit('PopListItem',index)
    }
  },
  watch:{
    lists(val) {
      console.log(val)
    }
  }
}
</script>

<style scoped>

</style>
