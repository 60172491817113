<template>
  <div>
    <b-row>
      <b-col
          xl="3"
          sm="6"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="info"
              >
                <feather-icon
                    size="24"
                    icon="ActivityIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{total}} {{$t('message.Paragraph')}}
              </h4>
              <b-card-text class="font-small-3 mb-0">
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
            v-if="total"
            no-body
        >
          <!-- apex chart -->
          <vue-apex-charts
              type="radialBar"
              height="245"
              class="my-2"
              :options="goalOverviewRadialBar"
              :series="[Math.ceil(dones*100/total)]"
          />
          <b-row class="text-center mx-0">
            <b-col
                cols="6"
                class="border-top border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                {{$t('Completed')}}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ dones }}
              </h3>
            </b-col>

            <b-col
                cols="6"
                class="border-top d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                {{$t('In_progress')}}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ total }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import UserHomeList from '@/views/loginpages/user/components/UserHomeList'
import { getUserStatistics } from '@/api/users'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'


Vue.use(VueApexCharts)

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  name: 'UserHome',
  components:{
    UserHomeList,
    VueApexCharts
  },
  data() {
    return {
      options: {},
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      total: 0,
      dones: 0,
    }
  },
  created() {
    this.fetchUserStatistics()
    },
  methods: {
    fetchUserStatistics() {
      getUserStatistics().then(res => {
        this.total = res.data.total;
        this.dones = res.data.done;
      })
    }
  }

}
</script>

<style scoped>

</style>
